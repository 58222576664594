import React, { FC, ReactNode } from 'react';
import { styled, ToggleButton as MuiToggleButton, ToggleButtonGroup as MuiToggleButtonGroup } from '@mui/material';

interface IToggleButtonGroup {
    value: any;
    children: ReactNode;
    onChange: (event: React.MouseEvent<HTMLElement>, value: any) => void;
    disabled?: boolean;
}

const ToggleButtonGroupBase = styled(MuiToggleButtonGroup)({
    '&.MuiToggleButtonGroup-root': {
        width: '100%',
        height: '100%',
    },
});

const ToggleButtonBase = styled(MuiToggleButton, {
    shouldForwardProp: (prop) => prop !== 'backgroundColor',
})<IToggleButton>(({ theme, backgroundColor }) => ({
    borderRadius: theme.borderRadius.large,
    borderColor: theme.palette.primary.grey,
    width: '100%',
    color: theme.palette.primary.purple,
    backgroundColor: backgroundColor ? backgroundColor : '',
    '&.Mui-selected': {
        backgroundColor: theme.palette.primary.purple,
        color: theme.palette.primary.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.purple,
        },
        '&.Mui-disabled': {
            backgroundColor: theme.palette.primary.greyBackground,
        },
    },
    '&.Mui-disabled': {
        backgroundColor: theme.palette.primary.white,
        color: theme.palette.primary.darkGrey,
    },
}));

export const ToggleButtonGroup: FC<IToggleButtonGroup> = ({ value, onChange, children, disabled }) => {
    return (
        <ToggleButtonGroupBase value={value} onChange={onChange} exclusive disabled={disabled}>
            {children}
        </ToggleButtonGroupBase>
    );
};

interface IToggleButton {
    value: any;
    children: ReactNode;
    backgroundColor?: string;
    disabled?: boolean;
}

export const ToggleButton: FC<IToggleButton> = ({ value, children, disabled, backgroundColor, ...props }) => {
    return (
        <ToggleButtonBase value={value} disabled={disabled} backgroundColor={backgroundColor} {...props}>
            {children}
        </ToggleButtonBase>
    );
};
