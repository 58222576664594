import * as React from 'react';
import Box from '@mui/material/Box';
import second from 'assets/images/organuz_loader.gif';
import { useTheme } from '@mui/material';

const Loader = () => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: '100%',
                backgroundColor: theme.palette.primary.white,
                zIndex: 100,
            }}
        >
            <img src={second} alt='טוען...' />
        </Box>
    );
};

export default Loader;
