import { FC, MouseEvent } from 'react';
import { ContractorPricePerKwTypes } from 'models/api/common';
import texts from 'texts.json';
import { ToggleButtonGroup, ToggleButton, Typography, Box } from 'components/atoms';
import { theme } from 'theme';
import { useController, useFormContext } from 'react-hook-form';
import { useIsDesktop } from 'hooks/useIsDesktop';

interface IProps {
    index: number;
    disabled: boolean;
}

const buttonsGroup = [
    {
        value: ContractorPricePerKwTypes.CONTRACTOR_PRICE_PER_KW_TYPE_DETAILED,
        text: texts.pricing.pricingContractor.pricePerKwTypes.detailed,
    },
    {
        value: ContractorPricePerKwTypes.CONTRACTOR_PRICE_PER_KW_TYPE_MANUAL,
        text: texts.pricing.pricingContractor.pricePerKwTypes.manual,
    },
];

const PricePerKwTypesButtons: FC<IProps> = ({ index, disabled }) => {
    const isDesktop = useIsDesktop();
    const { control } = useFormContext();

    const {
        field: { value, onChange },
    } = useController({ name: `rangesData.${index}.price_per_kw_type`, control });

    return (
        <Box display={'flex'} width={{ md: '300px', xs: '90%' }} height='40px'>
            <ToggleButtonGroup
                disabled={disabled}
                value={value}
                onChange={(e: MouseEvent<HTMLElement>, value: ContractorPricePerKwTypes) => {
                    e.stopPropagation();
                    return value !== null && onChange(value);
                }}
            >
                {buttonsGroup.map((button) => (
                    <ToggleButton value={button.value} key={button.value} backgroundColor={!isDesktop ? theme.palette.primary.white : ''}>
                        {isDesktop ? (
                            <Typography.Body6 color='inherit' font={theme.fontFamilies.bold}>
                                {button.text}
                            </Typography.Body6>
                        ) : (
                            <Typography.Body4 color='inherit'>{button.text}</Typography.Body4>
                        )}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </Box>
    );
};

export default PricePerKwTypesButtons;
