import { CompanyType, Item, SetCompanyDetailsOptionalFields, TCompanyDetailsExperience } from 'models/api/management';
import { ApiResponseYesNo, EmployeeTypes, IUploadCompanyDetails } from 'models/api/common';
import { TextFieldType } from 'components/atoms/TextField';
import texts from 'texts.json';
import { parseToNumber } from 'utils/parseToNumber';

export const transformCompanyDetails = (companyDetails?: Item) => {
    if (!companyDetails) return { item: {} as Item };
    return {
        item: {
            ...companyDetails,
            recommendations_app: companyDetails.recommendations_app.map((recommendation) => ({
                ...recommendation,
                project_power: parseToNumber(recommendation.project_power),
            })),
            constractor_4_25: parseToNumber(companyDetails.constractor_4_25),
            constractor_25_200: parseToNumber(companyDetails.constractor_25_200),
            constractor_200_400: parseToNumber(companyDetails.constractor_200_400),
            constractor_400_690: parseToNumber(companyDetails.constractor_400_690),
            constractor_690_plus: parseToNumber(companyDetails.constractor_690_plus),
            constractor_total_systems: parseToNumber(companyDetails.constractor_total_systems),
            entrepreneur_4_25: parseToNumber(companyDetails.entrepreneur_4_25),
            entrepreneur_25_200: parseToNumber(companyDetails.entrepreneur_25_200),
            entrepreneur_200_400: parseToNumber(companyDetails.entrepreneur_200_400),
            entrepreneur_400_690: parseToNumber(companyDetails.entrepreneur_400_690),
            entrepreneur_690_plus: parseToNumber(companyDetails.entrepreneur_690_plus),
            entrepreneur_total_systems: parseToNumber(companyDetails.entrepreneur_total_systems),
            total_systems_maintained: parseToNumber(companyDetails.total_systems_maintained),
            total_systems_maintained_kwp: parseToNumber(companyDetails.total_systems_maintained_kwp),
            years_in_the_field: parseToNumber(companyDetails.years_in_the_field),
        },
    };
};

export const transformCompanyExperience = (companyDetails?: TCompanyDetailsExperience) => {
    if (!companyDetails) return {} as TCompanyDetailsExperience;
    return {
        constractor_4_25: parseToNumber(companyDetails.constractor_4_25),
        constractor_25_200: parseToNumber(companyDetails.constractor_25_200),
        constractor_200_400: parseToNumber(companyDetails.constractor_200_400),
        constractor_400_690: parseToNumber(companyDetails.constractor_400_690),
        constractor_690_plus: parseToNumber(companyDetails.constractor_690_plus),
        constractor_total_systems: parseToNumber(companyDetails.constractor_total_systems),
        entrepreneur_4_25: parseToNumber(companyDetails.entrepreneur_4_25),
        entrepreneur_25_200: parseToNumber(companyDetails.entrepreneur_25_200),
        entrepreneur_200_400: parseToNumber(companyDetails.entrepreneur_200_400),
        entrepreneur_400_690: parseToNumber(companyDetails.entrepreneur_400_690),
        entrepreneur_690_plus: parseToNumber(companyDetails.entrepreneur_690_plus),
        entrepreneur_total_systems: parseToNumber(companyDetails.entrepreneur_total_systems),
        total_systems_maintained: parseToNumber(companyDetails.total_systems_maintained),
        total_systems_maintained_kwp: parseToNumber(companyDetails.total_systems_maintained_kwp),
        years_in_the_field: parseToNumber(companyDetails.years_in_the_field),
    };
};

const phoneFieldRules = {
    required: true,
    minLength: {
        value: 9,
        message: 'נא להזין מינימום 9 ספרות',
    },
    pattern: {
        value: /^\d+$/,
        message: 'נא להזין רק מספרים',
    },
};

export const personalDetailsLabels = () => {
    const { above, right, files, left, bottom } = texts.management.accordions.personalDetails;
    return {
        above: [
            {
                label: above.name,
                name: 'name',
                rules: {
                    required: 'שדה חובה',
                },
            },
            {
                label: above.address,
                name: 'address',
                rules: {
                    required: 'שדה חובה',
                },
            },
            {
                label: above.email,
                name: 'email',
                rules: {
                    required: 'שדה חובה',
                    pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: 'נא להזין כתובת מייל תקינה',
                    },
                },
            },
            {
                label: above.contact_name,
                name: 'contact_name',
                rules: {
                    required: 'שדה חובה',
                },
            },
            {
                label: above.id,
                name: 'id',
                rules: {
                    required: 'שדה חובה',
                },
            },
        ],
        right: [
            {
                label: right.phone,
                name: 'phone',
                rules: phoneFieldRules,
            },
            {
                label: right.fax,
                name: 'fax',
                rules: {
                    // required: false,
                    minLength: {
                        value: 9,
                        message: 'נא להזין מינימום 9 ספרות',
                    },
                    pattern: {
                        value: /^\d+$/,
                        message: 'נא להזין רק מספרים',
                    },
                },
            },
            {
                label: right.contact_phone,
                name: 'contact_phone',
                rules: phoneFieldRules,
            },
        ],
        files: [
            {
                label: files.certificate_of_incorporation,
                name: IUploadCompanyDetails.CERTIFICATE_OF_INCORPORATION,
                rules: {
                    required: 'שדה חובה',
                },
            },
            {
                label: files.authorized_dealer,
                name: IUploadCompanyDetails.AUTHORIZED_DEALER,
                rules: {
                    required: 'שדה חובה',
                },
            },
            {
                label: files.logoUpload,
                name: IUploadCompanyDetails.LOGO,
                rules: {
                    required: 'שדה חובה',
                },
                accept: '.jpg, .png',
            },
        ],
        left: {
            label: left.about,
            name: 'about',
            rules: {
                required: 'שדה חובה',
            },
            info: texts.management.accordions.personalDetails.aboutCompanyInfo,
        },
        bottom: [
            {
                label: bottom.constractor,
                name: 'type',
                value: CompanyType.CONSTRACTOR,
                rules: {
                    required: 'שדה חובה',
                },
            },
            {
                label: bottom.entrepreneur,
                value: CompanyType.ENTREPRENEUR,
                name: 'type',
                rules: {
                    required: 'שדה חובה',
                },
            },
        ],
    };
};

const connectedSystemsFields = (name: string) => {
    const { power_25_200, power_200_400, power_400_690, power_4_25, power_690_plus } = texts.management.accordions.companyExperience.labels;
    return [
        {
            label: power_4_25,
            name: `${name}_4_25`,
            type: TextFieldType.Number,
        },
        {
            label: power_25_200,
            name: `${name}_25_200`,
            type: TextFieldType.Number,
        },
        {
            label: power_200_400,
            name: `${name}_200_400`,
            type: TextFieldType.Number,
        },
        {
            label: power_400_690,
            name: `${name}_400_690`,
            type: TextFieldType.Number,
        },
        {
            label: power_690_plus,
            name: `${name}_690_plus`,
            type: TextFieldType.Number,
        },
    ];
};

export const companyExperienceLabels = [
    {
        header: texts.management.accordions.companyExperience.numberOfProjectsAsConstractor,
        fields: connectedSystemsFields('constractor'),
        total: texts.management.accordions.companyExperience.kwOfProjectsAsConstractor,
        name: 'constractor',
        type: CompanyType.CONSTRACTOR,
    },
    {
        header: texts.management.accordions.companyExperience.numberOfProjectsAsEntrepreneur,
        fields: connectedSystemsFields('entrepreneur'),
        total: texts.management.accordions.companyExperience.kwOfProjectsAsEntrepreneur,
        name: 'entrepreneur',
        type: CompanyType.ENTREPRENEUR,
    },
];

export const roofTypeSelectOptions = [
    {
        value: 'ROOF_TYPE_CONCRETE',
        label: 'גג בטון',
    },
    {
        value: 'ROOF_TYPE_TILES',
        label: 'גג רעפים',
    },
    {
        value: 'ROOF_TYPE_ASBESTOS',
        label: 'גג אסבסט',
    },
    {
        value: 'ROOF_TYPE_INSULATED_PANEL',
        label: 'גג פאנל מבודד',
    },
];

export const professionalsLabels = [
    { name: 'electrical_engineer' },
    { name: 'project_manager' },
    { name: 'electrician' },
    { name: 'monitoring' },
    { name: 'engineering_constructor' },
    { name: 'planning' },
    { name: 'installation_team' },
    { name: 'maintenance' },
];

export const professionalsCheckboxes = () => {
    const { doesntExist, subConstractor, employee } = texts.management.accordions.professionals.checkBoxes;
    return [
        {
            label: doesntExist,
            value: 'CONSTRACTOR_STAFF_TYPE_NONE' as keyof typeof EmployeeTypes,
        },
        {
            label: subConstractor,
            value: 'CONSTRACTOR_STAFF_TYPE_SUBCONSTRACTOR' as keyof typeof EmployeeTypes,
        },
        {
            label: employee,
            value: 'CONSTRACTOR_STAFF_TYPE_EMPLOYEE' as keyof typeof EmployeeTypes,
        },
    ];
};

export const certificatesLabels = [
    {
        name: 'works_at_heights',
    },
    {
        name: 'reg_const_160',
    },
    {
        name: 'reg_const_191',
    },
    {
        name: 'iso9001',
    },
    {
        name: 'defense_ministry_supplier',
    },
    {
        name: 'appropriate_insurances',
    },
];

export const yesNoCheckboxes = [
    {
        label: 'כן',
        value: ApiResponseYesNo.YN_ANSWER_YES,
    },
    {
        label: 'לא',
        value: ApiResponseYesNo.YN_ANSWER_NO,
    },
];

export const recommendationsLabels = [
    {
        name: 'project_power',
        type: TextFieldType.Number,
    },
    {
        name: 'roof_type',
    },
    {
        name: 'settlement',
    },
    {
        name: 'recommender_name',
    },
    {
        name: 'recommender_phone',
        rules: phoneFieldRules,
    },
];

export const signLabels = [
    {
        label: texts.management.accordions.sign.inputTitles.bidderName,
        name: 'bidder_name',
        rules: {
            required: true,
        },
    },
    {
        label: texts.management.accordions.sign.inputTitles.signatureAuthorized,
        name: 'signature_authorized',
        rules: {
            required: true,
        },
    },
    {
        label: texts.management.accordions.sign.inputTitles.signatureAuthorizedId,
        name: 'signature_authorized_id',
        rules: {
            required: true,
        },
    },
];

export const ErrorLabels = {
    name: 'שם החברה',
    address: 'כתובת החברה',
    email: 'כתובת מייל',
    contact_name: 'איש קשר - שם מלא',
    id: 'ח.פ/מס’ עוסק מורשה',
    phone: 'טלפון משרד',
    fax: 'פקס',
    contact_phone: 'טלפון איש קשר',
    certificate_of_incorporation: 'צרף תעודת התאגדות',
    authorized_dealer: 'צרף תעודת עוסק מורשה',
    logo: 'צרף לוגו עבור הצעת המחיר',
    about: 'כתוב טקסט קצר על חברתך',
    type: 'סוג חברה',
    constractor_4_25: '4-25 KWP קבלן',
    constractor_26_100: '26-100 KWP קבלן',
    constractor_101_200: '101-200 KWP קבלן',
    constractor_300_plus: '300 ומעלה KWp קבלן',
    constractor_total_systems: 'סה"כ מערכות קבלן',
    entrepreneur_4_25: '4-25 KWP יזם',
    entrepreneur_26_100: '26-100 KWP יזם',
    entrepreneur_101_200: '101-200 KWP יזם',
    entrepreneur_300_plus: '300 ומעלה KWp יזם',
    entrepreneur_total_systems: 'סה"כ מערכות יזם',
    electrical_engineer: 'מהנדס חשמל',
    project_manager: 'מנהל פרוייקט',
    maintenance: 'טכנאי תחזוקה',
    electrician: 'חשמלאי',
    monitoring: 'ניטור',
    engineering_constructor: 'קונסטרקטור',
    planning: 'מתכנן',
    installation_team: 'צוות התקנה',
    works_at_heights: 'עבודות בגובה',
    reg_const_160: 'קבלן רשום 160 חשמלאות ותקשורת',
    reg_const_191: 'קבלן רשום 191 מתקני אנרגיה סולארית',
    iso9001: 'ISO 9001',
    regulations_approval: 'אישור תקנון האתר',
    bidder_name: 'שם המציע',
    signature_authorized: 'חתימה ע"י מורשה חתימה',
    signature_authorized_id: 'מס זהות מורשה חתימה',
    defense_ministry_supplier: 'ספק משרד הביטחון',
};
