export const convertDateTimeWithSlashToHyphen = (dateString: string, isTimeString = false) => {
    const [datePart, timePart] = dateString.split(' ');
    const [day, month, year] = datePart.split('/').map(Number);
    if (!!timePart) {
        const [hours, minutes, seconds] = timePart.split(':').map(Number);
        return new Date(year, month - 1, day, hours, minutes, seconds);
    } else {
        return new Date(`${year}-${month}-${day}`);
    }
};
