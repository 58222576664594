import { AxiosRequestConfig } from 'axios';
import { ApiResponseYesNo, ChosenConstructionPriceList, EmployeeTypes, Response } from 'models/api/common';

export interface GetCompanyDetailsRes extends Response {
    item: Item;
}

export interface Item {
    item_id: string;
    type: CompanyType[];
    status: CompanyStatus;
    id: string;
    name: string;
    phone: string;
    fax: string;
    email: string;
    address: string;
    contact_name: string;
    contact_phone: string;
    about: string;
    certificate_of_incorporation: string;
    authorized_dealer: string;
    logo: string;
    years_in_the_field: string;
    constractor_4_25: string;
    constractor_25_200: string;
    constractor_200_400: string;
    constractor_400_690: string;
    constractor_690_plus: string;
    constractor_total_systems: string;
    entrepreneur_4_25: string;
    entrepreneur_25_200: string;
    entrepreneur_200_400: string;
    entrepreneur_400_690: string;
    entrepreneur_690_plus: string;
    entrepreneur_total_systems: string;
    total_systems_maintained: string;
    total_systems_maintained_kwp: string;
    electrical_engineer: keyof typeof EmployeeTypes;
    electrician: keyof typeof EmployeeTypes;
    engineering_constructor: keyof typeof EmployeeTypes;
    project_manager: keyof typeof EmployeeTypes;
    monitoring: keyof typeof EmployeeTypes;
    planning: keyof typeof EmployeeTypes;
    maintenance: keyof typeof EmployeeTypes;
    installation_team: keyof typeof EmployeeTypes;
    reg_const_160: ApiResponseYesNo;
    reg_const_191: ApiResponseYesNo;
    appropriate_insurances: boolean;
    works_at_heights: ApiResponseYesNo;
    annual_revenue: ApiResponseYesNo;
    cpa_approval: string;
    recommendations_app: Recommendation[];
    bidder_name: string;
    signature_authorized_id: string;
    signature_authorized: string;
    attorneys_affidavit: string;
    defense_ministry_supplier: ApiResponseYesNo;
    regulations_approval: ApiResponseYesNo;
    chosen_construction_price_list: ChosenConstructionPriceList;
}

export type TCompanyDetailsExperience = Pick<
    Item,
    | 'constractor_4_25'
    | 'constractor_25_200'
    | 'constractor_200_400'
    | 'constractor_400_690'
    | 'constractor_690_plus'
    | 'constractor_total_systems'
    | 'entrepreneur_4_25'
    | 'entrepreneur_25_200'
    | 'entrepreneur_200_400'
    | 'entrepreneur_400_690'
    | 'entrepreneur_690_plus'
    | 'entrepreneur_total_systems'
    | 'total_systems_maintained'
    | 'total_systems_maintained_kwp'
    | 'years_in_the_field'
>;

export interface Recommendation {
    item_id: string;
    project_power: string;
    recommender_name: string;
    recommender_phone: string;
    settlement: string;
    roof_type: string;
}

export type RecommendationWithId = Recommendation & { id: string };

export interface IImage {
    item_id: string;
    image: ImageDetails;
    power: string;
    roof_type: string;
}

export interface ImageDetails {
    src: string;
    width: number;
    height: number;
}

export enum CompanyType {
    CONSTRACTOR = 'COMPANY_TYPE_CONSTRACTOR',
    ENTREPRENEUR = 'COMPANY_TYPE_ENTREPRENEUR',
}

export enum CompanyStatus {
    APPROVED = 'REGISTRATION_STATUS_APPROVED',
    REJECTED = 'REGISTRATION_STATUS_REJECTED',
    SETTING_UP = 'REGISTRATION_STATUS_SETTING_UP',
    READY = 'REGISTRATION_STATUS_READY',
}

export type CreateRecommendationReq = Omit<Recommendation, 'item_id'>;

export interface SetRecommendationReq {
    item_id: string;
    item: CreateRecommendationReq;
}

export interface CreateProjectImageReq {
    power: string;
    roof_type: string;
}

export interface SetProjectImageReq {
    item_id: string;
    item: CreateProjectImageReq;
}

export type SetCompanyDetailsOptionalFields = Omit<
    Item,
    | 'recommendations'
    | 'images'
    | 'status'
    | 'attorneys_affidavit'
    | 'authorized_dealer'
    | 'cpa_approval'
    | 'certificate_of_incorporation'
    | 'logo'
>;

export interface SetCompanyDetailsReq extends SetCompanyDetailsOptionalFields {}

export interface GetCompanyRecommendationsRes extends Response {
    itemFound: number;
    items: Recommendation[];
}

export interface GetCompanyImagesRes extends Response {
    itemFound: number;
    items: IImage[];
}

export interface UploadImageReq {
    formData: FormData;
    config?: AxiosRequestConfig;
}

export interface IUploadProjectImageRes extends Response {
    item: IImage;
}
