/* eslint-disable max-lines */
export interface Response {
    status: string;
}

export interface SetCreateRes extends Response {
    item: {
        item_id: string;
    };
}

export enum ApiResponseYesNo {
    YN_ANSWER_YES = 'YN_ANSWER_YES',
    YN_ANSWER_NO = 'YN_ANSWER_NO',
}

export enum EmployeeTypes {
    CONSTRACTOR_STAFF_TYPE_EMPLOYEE = 'עובד חברה',
    CONSTRACTOR_STAFF_TYPE_SUBCONSTRACTOR = 'קבלן משנה',
    CONSTRACTOR_STAFF_TYPE_NONE = 'אין בחברה',
}

export enum PriceListStatuses {
    PRICE_LIST_STATUS_ACTIVE = 'PRICE_LIST_STATUS_ACTIVE',
    PRICE_LIST_STATUS_INACTIVE = 'PRICE_LIST_STATUS_INACTIVE',
}

export enum ChosenConstructionPriceList {
    ENTREPRENEUR_CONSTRUCTION_PRICE_LIST_DEFAULT = 'ENTREPRENEUR_CONSTRUCTION_PRICE_LIST_DEFAULT',
    ENTREPRENEUR_CONSTRUCTION_PRICE_LIST_MINE = 'ENTREPRENEUR_CONSTRUCTION_PRICE_LIST_MINE',
}

export enum ContractorPricePerKwTypes {
    CONTRACTOR_PRICE_PER_KW_TYPE_DETAILED = 'CONSTRACTOR_PRICE_PER_KW_TYPE_DETAILED',
    CONTRACTOR_PRICE_PER_KW_TYPE_MANUAL = 'CONSTRACTOR_PRICE_PER_KW_TYPE_MANUAL',
}

export enum IDownloadCompanyFileFieldsReq {
    QUOTATION_PDF = 'upload_pdf',
    EXPANDED_QUOTATION_PDF = 'upload_expanded_pdf',
}

export enum IUploadCompanyDetails {
    ATTORNEYS_AFFIDAVIT = 'attorneys_affidavit',
    CERTIFICATE_OF_INCORPORATION = 'certificate_of_incorporation',
    CPA_APPROVAL = 'cpa_approval',
    AUTHORIZED_DEALER = 'authorized_dealer',
    LOGO = 'logo',
}

export enum IDownloadSettingsFilesFieldsReq {
    CPA_APPROVAL_FORM = 'cpa_approval_form',
    ATTORNEYS_AFFIDAVIT_FORM = 'attorneys_affidavit_form',
    FULL_TECHNICAL_SPECIFICATIONS = 'full_technical_specifications',
}

export interface IRemainingSearchesRes extends Response {
    item: {
        item_id: string;
        max_projects: number;
        remaining_projects: number;
    };
}

export interface ITypeLinks {
    item_id: string;
    title: string;
}

export enum EAmbassadorType {
    AMBASSADOR_TYPE_CLIENT = 'AMBASSADOR_TYPE_CLIENT',
    AMBASSADOR_TYPE_AGENT = 'AMBASSADOR_TYPE_AGENT',
    AMBASSADOR_TYPE_COMPANY = 'AMBASSADOR_TYPE_COMPANY',
    AMBASSADOR_TYPE_OTHER = 'AMBASSADOR_TYPE_OTHER',
}

export interface IDownloadQuotationReq {
    itemId: string;
    type: IDownloadCompanyFileFieldsReq;
}

export enum EProjectStatusType {
    PROJECT_STATUS_NEW = 'PROJECT_STATUS_NEW',
    PROJECT_STATUS_DRAFT = 'PROJECT_STATUS_DRAFT',
    PROJECT_STATUS_QUOTATIONS_CREATED = 'PROJECT_STATUS_QUOTATIONS_CREATED',
    PROJECT_STATUS_QUOTATION_ELECTED = 'PROJECT_STATUS_QUOTATION_ELECTED',

}
// company
// new - navigate to iframe
// draft - results page with option to edit
// quotations created - results page without option to edit
// quotation elected -results page without option to edit

// client agent
// new - navigate to iframe
// draft - results page with option to edit
// quotations created - offers page
// quotation elected - offers page

export enum EQuotationStatusType {
    QUOTATION_STATUS_VALID = 'QUOTATION_STATUS_VALID',
    QUOTATION_STATUS_EXPIRED = 'QUOTATION_STATUS_EXPIRED',
    QUOTATION_STATUS_ELECTED = 'QUOTATION_STATUS_ELECTED',
    QUOTATION_STATUS_NOT_ELECTED = 'QUOTATION_STATUS_NOT_ELECTED',
}

// company quotations temporarily not for action
// valid - navigate to iframe
// expired - results page with option to edit
// elected  - offers page
// not elected - offers page