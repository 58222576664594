import React, { FC, Fragment } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, InfoTooltip, Typography } from 'components/atoms';
import { useTheme } from '@mui/material';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { buildSimulatorData, simulatorDataCategories } from 'data/PricingContractorDataPrecessing';
import { GetContractorSimulatorItem } from 'models/api/pricingContractor';
import { useGetCompanySettings } from 'api/queries/common';

const titles = ['עלויות', 'ציוד ואחריות', 'תנאי תשלום'];

export interface IProps {
    contractorSimulatorData: GetContractorSimulatorItem;
}

const PricingContractorSimulatorDetails: FC<IProps> = ({ contractorSimulatorData }) => {
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    const { data: settings } = useGetCompanySettings();

    const simulatorData = buildSimulatorData(contractorSimulatorData, settings?.data.item);

    return (
        <>
            {isDesktop ? (
                Object.entries(simulatorDataCategories(contractorSimulatorData.s_price_per_kw_type)).map(
                    ([category, value], index, arr) => {
                        const lastIndex = arr.length - 1;
                        const categoryValues = simulatorData[category as keyof typeof simulatorData];
                        return (
                            <Fragment key={index}>
                                <Box mb='35px'>
                                    <Typography.Body2 color={theme.palette.primary.purple}>{titles[index]}</Typography.Body2>
                                </Box>
                                <Grid container columns={7} rowGap={'40px'} columnGap={'8px'} key={index}>
                                    {value.map((item: any, index: number) => {
                                        const isTotalLabel = item.name === 's_total_price' || item.name === 's_price_per_kw';
                                        const value = categoryValues[item.name as keyof typeof categoryValues];

                                        return (
                                            <Grid item md={1} key={index}>
                                                <Box display='flex' flexDirection='column' gap={{ xs: '5px', md: '15px' }}>
                                                    <Box display='flex' columnGap='8px' alignItems={'baseline'}>
                                                        <Typography.Body4>{item.title.label}</Typography.Body4>
                                                        {item.title.info && (
                                                            <InfoTooltip
                                                                firstTitle={item.title.info?.firstTitle}
                                                                firstText={item.title.info?.firstText}
                                                                secondTitle={item.title.info?.secondTitle}
                                                                secondText={item.title.info?.secondText}
                                                            />
                                                        )}
                                                    </Box>

                                                    {isTotalLabel ? (
                                                        <Typography.Body1 color={theme.palette.primary.purple}>{value}</Typography.Body1>
                                                    ) : (
                                                        <Typography.Body2 font={theme.fontFamilies.light}>{value}</Typography.Body2>
                                                    )}
                                                </Box>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                                {index !== lastIndex && (
                                    <Box width='100%' my='45px'>
                                        <Divider />
                                    </Box>
                                )}
                            </Fragment>
                        );
                    }
                )
            ) : (
                <Box display='flex' flexDirection='column' gap={10}>
                    {Object.entries(simulatorDataCategories(contractorSimulatorData.s_price_per_kw_type)).map(
                        ([category, value], index) => (
                            <Accordion key={index}>
                                <AccordionSummary>
                                    <Box px='10px'>
                                        <Typography.Body3 color={theme.palette.primary.purple}>{titles[index]}</Typography.Body3>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container columns={7} key={index} px='10px'>
                                        {value.map((item: any, index: number, arr: any[]) => {
                                            const isTotalLabel = item.name === 'totalCost' || item.name === 'totalForOneKW';
                                            const categoryValues = simulatorData[category as keyof typeof simulatorData];
                                            const value = categoryValues[item.name as keyof typeof categoryValues];

                                            const lastIndex = arr.length - 1;
                                            return (
                                                <Fragment key={index}>
                                                    <Grid item xs={7} key={index}>
                                                        <Box display='flex' flexDirection='column' gap={{ xs: '5px', md: '15px' }}>
                                                            <Box display='flex' justifyContent={'space-between'}>
                                                                <Typography.Body4>{item.title.label}</Typography.Body4>
                                                                <InfoTooltip
                                                                    firstTitle={item.title.info?.firstTitle}
                                                                    firstText={item.title.info?.firstText}
                                                                    secondTitle={item.title.info?.secondTitle}
                                                                    secondText={item.title.info?.secondText}
                                                                />
                                                            </Box>
                                                            {isTotalLabel ? (
                                                                <Typography.Body1 color={theme.palette.primary.purple}>
                                                                    {value}
                                                                </Typography.Body1>
                                                            ) : (
                                                                <Typography.Body2 font={theme.fontFamilies.light}>{value}</Typography.Body2>
                                                            )}
                                                        </Box>
                                                    </Grid>
                                                    {index !== lastIndex && (
                                                        <Box width='100%' my='10px'>
                                                            <Divider />
                                                        </Box>
                                                    )}
                                                </Fragment>
                                            );
                                        })}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        )
                    )}
                </Box>
            )}
        </>
    );
};

export default PricingContractorSimulatorDetails;
