import { useTheme } from '@mui/material';
import { Dialog, Box, Checkbox, Typography } from 'components/atoms';
import { FC } from 'react';
import texts from 'texts.json';

interface IProps {
    open: boolean;
    showAgain: boolean;
    onShowAgainChange: (value: boolean) => void;
    onClose: () => void;
}

const VideoTutorialDialog: FC<IProps> = ({ open, onClose, showAgain, onShowAgainChange }) => {
    const theme = useTheme();
    return (
        <Dialog open={open} onClose={onClose} desktopWidth={'58%'} fullWidth isCloseIcon>
            <Box display={'flex'} flexDirection={'column'} rowGap={'20px'} p={{ md: '0 30px 30px', xs: '0 20px 20px' }}>
                <div style={{ padding: '56.25% 0 0 0', position: 'relative', width: '100%' }}>
                    <iframe
                        src='https://player.vimeo.com/video/1018205159?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
                        frameBorder={0}
                        allow='autoplay; fullscreen; picture-in-picture; clipboard-write'
                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                        title='סרטון הדרכה'
                    ></iframe>
                </div>

                <Box display={'flex'} alignItems={'center'} columnGap={'10px'}>
                    <Checkbox
                        size={20}
                        checked={!showAgain}
                        onChange={(e, checked) => onShowAgainChange(!checked)}
                        borderColor={theme.palette.primary.purple}
                    />
                    <Typography.Body3>{texts.videoTutorial.doNotShowAgain}</Typography.Body3>
                </Box>
            </Box>
        </Dialog>
    );
};

export default VideoTutorialDialog;
