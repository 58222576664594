import React, { FC } from 'react';
import { useTheme } from '@mui/material';
import { Box, Button, Grid, Typography, Link, Icon, Divider, Card } from 'components/atoms';
import { useFormContext } from 'react-hook-form';
import RangeTitle from 'components/molecules/pricing/pricingEntrepreneur/RangeTitle';
import { Orientation } from 'components/atoms/Divider';
import EditButtonSection from 'components/molecules/pricing/EditButtonSection';
import PricingCardWithInfo from 'components/molecules/pricing/PricingCardWithInfo';
import { entrepreneurAccordionLabels } from 'data/PricingEntrepreneurDataProcessing';
import ControllerSelect from 'components/organisms/ControllerSelect';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { EntrepreneurPriceListRanges } from 'models/PricingEntrepreneur.model';
import { IPricingPanelData } from 'components/molecules/pricing/pricingEntrepreneur/PricingEntrepreneurRanges';
import { buildPricingAccordionData } from 'data/pricing';
import PricingCopyPastePanel from 'components/molecules/pricing/PricingCopyPastePanel';
import { useGetCompanySettings } from 'api/queries/common';
import { buildTypeLinksSelectOptions } from 'utils/common';
import { TextFieldType } from 'components/atoms/TextField';
import PricingPropertyType from 'components/molecules/pricing/PricingPropertyType';

enum SelectFields {
    terms_of_payment = 'terms_of_payment',
}
interface IProps {
    data: EntrepreneurPriceListRanges;
    isEdit: boolean;
    showRange?: boolean;
    fieldIndex: number;
    isItemToDelete: boolean;
    removeRange: (id: string) => void;
    setIsEdit: (value: string) => void;
    updateRange: (rangeId: string) => void;
    onCopy?: () => void;
    onPaste?: () => void;
    onDuplicate?: () => void;
    setPricingPanelData: ({ data, fieldIndex }: IPricingPanelData) => void;
}

const PricingEntrepreneurAccordionDetails: FC<IProps> = ({
    data,
    removeRange,
    isEdit,
    setIsEdit,
    showRange,
    setPricingPanelData,
    fieldIndex,
    updateRange,
    isItemToDelete,
    onCopy,
    onPaste,
    onDuplicate,
}) => {
    const { data: settings } = useGetCompanySettings();
    const accordionData: any = buildPricingAccordionData(data, entrepreneurAccordionLabels(settings?.data.item));
    const { control, handleSubmit, reset } = useFormContext();
    const isDesktop = useIsDesktop();
    const theme = useTheme();
    const paymentTerms = settings?.data.item.terms_of_payment_type_links;

    const handleSave = () => {
        handleSubmit(
            () => {
                updateRange(data.id);
            },
            (errors: any) => console.log(errors)
        )();
    };

    return (
        <>
            {!isDesktop && (
                <Box sx={{ position: 'absolute', right: 80, top: 30, display: 'flex', gap: '10px' }}>
                    <PricingCopyPastePanel onCopy={onCopy} onDuplicate={onDuplicate} onPaste={onPaste} />
                </Box>
            )}
            <Box mb='25px'>
                <Box display='flex' alignItems='center' gap='30px' justifyContent='end'>
                    <EditButtonSection
                        dataId={data.id}
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                        onSave={handleSave}
                        onCancel={() => {
                            reset();
                            setIsEdit('');
                        }}
                    />
                    {isItemToDelete && (
                        <Box>
                            {isDesktop ? (
                                <Link
                                    textDecorationColor={theme.palette.primary.purple}
                                    onClick={() => {
                                        removeRange(data.id);
                                    }}
                                >
                                    <Typography.Body4 color={theme.palette.primary.purple}>מחיקת טווח</Typography.Body4>
                                </Link>
                            ) : (
                                <Button.Icon
                                    onClick={() => {
                                        removeRange(data.id);
                                    }}
                                >
                                    <Icon.Trash />
                                </Button.Icon>
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
            {showRange && (
                <Box p='10px 0 20px 0' display='flex' justifyContent='center' alignItems='center'>
                    <RangeTitle size='regular' data={data} isEdit={isEdit} fieldIndex={fieldIndex} />
                </Box>
            )}
            <Box mb={'30px'}>
                <PricingPropertyType isEdit={isEdit} index={fieldIndex} />
            </Box>
            <Card.GreyBorderCard>
                <Grid container columns={10} columnSpacing={22}>
                    {accordionData?.map((item: any, index: number) => {
                        const isSelectFiled = item.name === SelectFields.terms_of_payment;
                        const selectValue = paymentTerms?.find((terms) => terms.item_id === item.value)?.title;
                        return (
                            <Grid item md={2} xs={12} key={index}>
                                <PricingCardWithInfo
                                    title={item.title.label}
                                    info={item.title.info}
                                    value={!isSelectFiled ? item.value : selectValue}
                                    specialChar={item.specialChar}
                                    isEdit={isEdit}
                                    control={control}
                                    rules={item.rules}
                                    type={TextFieldType.Number}
                                    name={`rangesData.${fieldIndex}.${item.name}`}
                                    component={
                                        isSelectFiled && (
                                            <ControllerSelect
                                                control={control}
                                                name={`rangesData.${fieldIndex}.${item.name}`}
                                                options={buildTypeLinksSelectOptions(paymentTerms) || []}
                                            />
                                        )
                                    }
                                />
                                {!isDesktop && (
                                    <Box width='95%' mx='auto'>
                                        <Divider orientation={Orientation.Horizontal} borderColor={theme.palette.primary.black} />
                                    </Box>
                                )}
                            </Grid>
                        );
                    })}
                </Grid>
            </Card.GreyBorderCard>
            {/* <Box p={{ xs: '30px 0 30px 0', md: '20px 0 0 0' }} width={{ xs: '100%', md: '130px' }}>
                <Button.Contained
                    onClick={() => {
                        setPricingPanelData({
                            fieldIndex,
                            data,
                        });
                    }}
                    size={isDesktop ? ButtonSize.xSmall : ButtonSize.MEDIUM}
                >
                    <Typography.Body4 color={theme.palette.primary.white}>מחירון תוספות</Typography.Body4>
                </Button.Contained>
            </Box> */}
        </>
    );
};

export default PricingEntrepreneurAccordionDetails;
