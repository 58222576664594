import React, { FC, Fragment } from 'react';
import { Box, Divider, Grid, ToggleButton, ToggleButtonGroup, Typography } from 'components/atoms';
import { Control, useFormContext, useWatch } from 'react-hook-form';
import { useTheme } from '@mui/material';
import PricingCardWithInfo from 'components/molecules/pricing/PricingCardWithInfo';
import { contractorAccordionCostsLabels } from 'data/PricingContractorDataPrecessing';
import { useIsDesktop } from 'hooks/useIsDesktop';
import CardWithInfo from 'components/molecules/CardWithInfo';
import { ConvertersPriceCalcTypes, USDExchangeRateTypes } from 'models/api/pricingContractor';
import { addCommaToNumber } from 'utils/addCommaToNumber';
import { TextFieldType } from 'components/atoms/TextField';
import { PricingContractorRangesData } from 'models/PricingData';
import { buildPricingAccordionData } from 'data/pricing';
import { useGetCompanySettings } from 'api/queries/common';
import texts from 'texts.json';
interface IProps {
    data?: PricingContractorRangesData['costs'];
    fieldIndex: number;
    control: Control;
    isEdit: boolean;
}

const PricingContractorAccordionDetailsCosts: FC<IProps> = ({ data, fieldIndex, control, isEdit }) => {
    const { convertersCalculator, priceFor1KwDC, updatedChangeRateUsd, enterUsdChangeRate } =
        texts.pricing.pricingContractor.range.costTitles.buttonTitles;
    const theme = useTheme();
    const isDesktop = useIsDesktop();
    const { setValue } = useFormContext();
    const { data: settings } = useGetCompanySettings();

    const usdRateManuallyValue = useWatch({
        control,
        name: `rangesData.${fieldIndex}.costs.usd_rate_manually`,
    });

    const usdRateValue = useWatch({
        control,
        name: `rangesData.${fieldIndex}.costs.usd_rate_type`,
    });

    const convertersPriceValue = useWatch({
        control,
        name: `rangesData.${fieldIndex}.costs.converters_price`,
    });

    const convertersPriceCalc = useWatch({
        control,
        name: `rangesData.${fieldIndex}.costs.converters_price_calc`,
    });

    const pricePerKwType = useWatch({
        control,
        name: `rangesData.${fieldIndex}.price_per_kw_type`,
    });

    if (!data) return null;

    const { usd_rate_manually, ...otherData } = data;
    const costsData = buildPricingAccordionData(otherData, contractorAccordionCostsLabels(settings?.data.item, pricePerKwType));

    const handleButtonGroupChange = (newValue: USDExchangeRateTypes | ConvertersPriceCalcTypes, isUsdRate: boolean) => {
        if (newValue === null) return;
        setValue(`rangesData.${fieldIndex}.costs.` + (isUsdRate ? `usd_rate_type` : `converters_price_calc`), newValue, {
            shouldDirty: true,
        });
    };

    return (
        <Grid container bgcolor='primary.white'>
            {costsData.map((item: any, index: number) => {
                const isUsdRate = item.name === 'usd_rate_type';
                const isConverters = item.name === 'converters_price_calc';
                const isPanels = item.name === 'panels';
                const step = isPanels || isUsdRate ? 0.01 : 1;

                if (item.name === 'manual_price_per_kw') {
                    return (
                        <Grid item md={2} xs={12} key={index}>
                            <PricingCardWithInfo
                                info={item.title.info}
                                title={item.title.label}
                                value={addCommaToNumber(item.value)}
                                control={control}
                                isEdit={isEdit}
                                name={`rangesData.${fieldIndex}.costs.${item.name}`}
                                rules={item.rules}
                                type={TextFieldType.Number}
                                step={step}
                            />
                        </Grid>
                    );
                }
                if (item.name === 'price_per_kw' && convertersPriceCalc === ConvertersPriceCalcTypes.CONVERTERS_PRICE_CALC_KW) {
                    return (
                        <Grid item md={2} xs={12} key={index}>
                            <CardWithInfo
                                title={item.title.label}
                                border={false}
                                firstTitle={item.title.info?.firstTitle}
                                firstText={item.title.info?.firstText}
                                secondTitle={item.title.info?.secondTitle}
                                secondText={item.title.info?.secondText}
                            >
                                <Typography.Body1 font={theme.fontFamilies.bold}>{item.value}</Typography.Body1>
                            </CardWithInfo>
                        </Grid>
                    );
                }
                return (
                    <Fragment key={index}>
                        {!isDesktop && index !== 0 && (
                            <Box width='95%' m='10px auto'>
                                <Divider />
                            </Box>
                        )}
                        {!isUsdRate && !isConverters ? (
                            <Grid item md={2} xs={12}>
                                <PricingCardWithInfo
                                    info={item.title.info}
                                    title={item.title.label}
                                    value={addCommaToNumber(item.value)}
                                    control={control}
                                    isEdit={isEdit}
                                    name={`rangesData.${fieldIndex}.costs.${item.name}`}
                                    rules={item.rules}
                                    type={TextFieldType.Number}
                                    step={step}
                                />
                            </Grid>
                        ) : (
                            <Grid item md={4} xs={12} key={index}>
                                <PricingCardWithInfo
                                    info={item.title.info}
                                    title={item.title.label}
                                    value={isUsdRate ? usdRateManuallyValue : convertersPriceValue}
                                    control={control}
                                    isEdit={
                                        isEdit
                                            ? isUsdRate
                                                ? usdRateValue === USDExchangeRateTypes.USD_RATE_TYPE_MANUALLY
                                                : convertersPriceCalc === ConvertersPriceCalcTypes.CONVERTERS_PRICE_CALC_KW
                                            : false
                                    }
                                    name={`rangesData.${fieldIndex}.costs.` + (isUsdRate ? `usd_rate_manually` : `converters_price`)}
                                    type={TextFieldType.Number}
                                    rules={item.rules}
                                    step={step}
                                >
                                    <Box height='40px'>
                                        <ToggleButtonGroup
                                            disabled={!isEdit}
                                            value={isUsdRate ? usdRateValue : convertersPriceCalc}
                                            onChange={(_, newValue: USDExchangeRateTypes | ConvertersPriceCalcTypes) =>
                                                handleButtonGroupChange(newValue, isUsdRate)
                                            }
                                        >
                                            <ToggleButton
                                                value={
                                                    isUsdRate
                                                        ? USDExchangeRateTypes.USD_RATE_TYPE_EXCHANGE_RATE
                                                        : ConvertersPriceCalcTypes.CONVERTERS_PRICE_CALC_PRICE_LIST
                                                }
                                                disabled={!!isConverters}
                                            >
                                                <Typography.Body6 color='inherit' font={theme.fontFamilies.bold}>
                                                    {isUsdRate ? updatedChangeRateUsd : convertersCalculator}
                                                </Typography.Body6>
                                            </ToggleButton>
                                            <ToggleButton
                                                value={
                                                    isUsdRate
                                                        ? USDExchangeRateTypes.USD_RATE_TYPE_MANUALLY
                                                        : ConvertersPriceCalcTypes.CONVERTERS_PRICE_CALC_KW
                                                }
                                            >
                                                <Typography.Body6 color='inherit' font={theme.fontFamilies.bold}>
                                                    {isUsdRate ? enterUsdChangeRate : priceFor1KwDC}
                                                </Typography.Body6>
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Box>
                                </PricingCardWithInfo>
                            </Grid>
                        )}
                    </Fragment>
                );
            })}
        </Grid>
    );
};

export default PricingContractorAccordionDetailsCosts;
