import LoginDialogs from 'components/organisms/authentication/LoginDialogs';
import React, { FC, ReactNode, createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { getUser } from 'utils/getUser';
import { Dialogs, IAuthContext, LoginRes } from 'models/api/auth';
import { useLocation } from 'react-router-dom';
import { PATH } from 'paths';
import queryClient from 'api/apiClient';
import { USER_STORAGE_KEY } from 'consts/storage.const';
import storageService from 'services/storage.service';

interface IProps {
    children: ReactNode;
}

export const AuthContext = createContext<IAuthContext>({
    user: undefined,
    showDialog: null,
    setShowDialog: () => {},
    handleLogin: (user: LoginRes, saveToStorage: boolean, callback: () => void) => {},
    handleLogout: () => {},
});

const AuthProvider: FC<IProps> = ({ children }) => {
    const path = useLocation().pathname;

    const [user, setUser] = useState<LoginRes | undefined>(getUser());
    const [showDialog, setShowDialog] = useState<Dialogs | null>(null);
    const isFreePath = path.includes(PATH.ADDRESS) || path.includes(PATH.GET_ADDRESS) || path === '/';

    const handleLogin = useCallback((user: LoginRes, saveToStorage: boolean, callback: Function) => {
        storageService.set(USER_STORAGE_KEY, user, saveToStorage);
        setUser(user);
        callback();
    }, []);

    const handleLogout = useCallback(() => {
        setUser(undefined);
        storageService.clear(USER_STORAGE_KEY);
        queryClient.clear();
    }, []);

    useEffect(() => {
        if (!user && !!getUser()) {
            setUser(getUser());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isFreePath && !user?.user) {
            setShowDialog(Dialogs.LOGIN);
        }
    }, [isFreePath, user?.user]);

    const values = useMemo(
        () => ({
            user,
            showDialog,
            setShowDialog,
            handleLogin,
            handleLogout,
        }),
        [user, showDialog, setShowDialog, handleLogin, handleLogout]
    );

    return (
        <AuthContext.Provider value={values}>
            {user?.user || (!user?.user && isFreePath) ? children : null}
            {showDialog && <LoginDialogs showDialog={showDialog} setShowDialog={setShowDialog} />}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
