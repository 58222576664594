import { useTheme } from '@mui/material';
import { useSetProjectCalculator } from 'api/queries/calculator';
import { Box, Typography } from 'components/atoms';
import ContentSection from 'components/molecules/ContentSection';
import ContentSectionMobile from 'components/molecules/ContentSectionMobile';
import InfoModal from 'components/molecules/InformationModal';
import { MapContext } from 'contexts/MapContext';
import { useIsDesktop } from 'hooks/useIsDesktop';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import texts from 'texts.json';
import { renderLinkInText } from 'utils/renderText';

const Type: FC = () => {
    const navigate = useNavigate();
    const isDesktop = useIsDesktop();
    const theme = useTheme();
    const { setFooterButtonData, setShowMapSection, getIframeDataFunction, setIsIframeDone, calculator, isPanelsExist, setIsPanelsExist } =
        useContext(MapContext);
    const { mutate: setProject } = useSetProjectCalculator();
    const { text, explanationLink, explanationLinkText, button, title, subtitle, media } = texts.calculator.stepRoof.type;
    const [modal, setModal] = useState(false);

    const handleClick = () => {
        getIframeDataFunction();
        console.log('🚀TYPE_GET_IFRAME_DATA_FUNCTION');
    };

    const onSetProject = useCallback(() => {
        if (calculator?.projectId && (calculator?.roofAccess || calculator?.funding)) {
            setProject(
                {
                    itemId: calculator.projectId,
                },
                {
                    onSuccess: async () => {
                        onMoveToNextStep();
                    },
                }
            );
            return;
        }
        onMoveToNextStep();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calculator, setProject]);

    const onMoveToNextStep = () => {
        setIsIframeDone(false);
        navigate({
            pathname: texts.calculator.stepRoof.type.button.link,
        });
        setIsPanelsExist(undefined);
    };

    const handleMobileClick = () => {
        setShowMapSection(true);
        setFooterButtonData({
            text: button.text,
            onClick: handleClick,
        });
    };

    useEffect(() => {
        console.log('🚀 TYPE_IS_PANELS_EXIST', isPanelsExist);
        if (isPanelsExist === true) {
            onSetProject();
        } else if (isPanelsExist === false) {
            setModal(true);
        }
    }, [isPanelsExist, onSetProject]);

    return (
        <Box width='100%' height='100%'>
            {isDesktop ? (
                <ContentSection onClick={handleClick} buttonText={button.text} title={title} text={subtitle} media={media.video}>
                    <Box textAlign='start'>
                        <Typography.DangerouslyHtml font={theme.fontFamilies.bold}>
                            {renderLinkInText(text, explanationLinkText, explanationLink)}
                        </Typography.DangerouslyHtml>
                    </Box>
                </ContentSection>
            ) : (
                <ContentSectionMobile
                    onClick={handleMobileClick}
                    buttonText={texts.calculator.stepAddress.address.button.text}
                    title={title}
                    text={subtitle}
                    media={media.video}
                >
                    <Box width='80%' mx='auto' mt='24px' textAlign='start'>
                        <Typography.DangerouslyHtml font={theme.fontFamilies.regular}>
                            {renderLinkInText(text, explanationLinkText, explanationLink)}
                        </Typography.DangerouslyHtml>
                    </Box>
                </ContentSectionMobile>
            )}
            <InfoModal
                open={modal}
                isCloseIcon
                onClose={() => {
                    setModal(false);
                    setIsPanelsExist(undefined);
                }}
            >
                <Box sx={{ width: '100%', marginTop: '46px', textAlign: 'center' }}>
                    <Typography.Body2 color={theme.palette.primary.black}>{texts.calculator.stepRoof.type.noPanelsModal}</Typography.Body2>
                </Box>
            </InfoModal>
        </Box>
    );
};

export default Type;
