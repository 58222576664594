import { styled } from '@mui/material';
import { Select, Box, Typography } from 'components/atoms';
import { IMyOffer } from 'models/myOffers';
import { FC, useState, useEffect } from 'react';
import { convertDateTimeWithSlashToHyphen } from 'utils/date.util';

const Wrapper = styled('div')({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    columnGap: '10px',
});

enum ESortQuotationsByDate {
    NEW = 0,
    OLD = 1,
}

const sortQuotationsOptions = [
    { value: ESortQuotationsByDate.NEW, label: 'מהחדש לישן' },
    { value: ESortQuotationsByDate.OLD, label: 'מהישן לחדש' },
];

interface IProps {
    projects?: IMyOffer[];
    setProjects: (projects: IMyOffer[]) => void;
}

const sortProjects = (projects: IMyOffer[], sortQuotations: ESortQuotationsByDate) => {
    return [...projects].sort((a, b) => {
        const dateA = convertDateTimeWithSlashToHyphen(a.tc_item_creation);
        const dateB = convertDateTimeWithSlashToHyphen(b.tc_item_creation);
        if (sortQuotations === ESortQuotationsByDate.NEW) {
            return dateB.getTime() - dateA.getTime();
        }
        return dateA.getTime() - dateB.getTime();
    });
};

const SortQuotations: FC<IProps> = ({ projects, setProjects }) => {
    const [sortQuotations, setSortQuotations] = useState(ESortQuotationsByDate.NEW);

    useEffect(() => {
        if (!projects) {
            return;
        }
        const sortedProjects = sortProjects(projects, sortQuotations);
        setProjects(sortedProjects);
    }, [projects, setProjects, sortQuotations]);

    return (
        <Wrapper>
            <Typography.Body5>סידור הצעות לפי:</Typography.Body5>
            <Box width={{ md: '195px', xs: 'unset' }} flex={{ xs: 1, md: 'unset' }}>
                <Select
                    options={sortQuotationsOptions}
                    onChange={(e) => setSortQuotations(e.target.value)}
                    value={sortQuotations as unknown as string}
                />
            </Box>
        </Wrapper>
    );
};

export default SortQuotations;
