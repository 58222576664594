import { Box } from 'components/atoms';
import DefaultAppBar from 'components/organisms/appBar/DefaultAppBar';

const AppBar = () => {
    return (
        <Box bgcolor={(theme) => theme.palette.primary.white} className='print_hide'>
            <DefaultAppBar />
        </Box>
    );
};

export default AppBar;
