import { ReactComponent as IconAccount } from 'assets/icons/icon-account.svg';
import { ReactComponent as IconAccountColorful } from 'assets/icons/icon-account-colorful.svg';
import { ReactComponent as IconBack } from 'assets/icons/icon-back.svg';
import { ReactComponent as IconBook } from 'assets/icons/icon-book.svg';
import { ReactComponent as IconCheck } from 'assets/icons/icon-check.svg';
import { ReactComponent as IconDownArrow } from 'assets/icons/icon-down-arrow.svg';
import { ReactComponent as IconInfo } from 'assets/icons/icon-info.svg';
import { ReactComponent as IconSolarPanel } from 'assets/icons/icon-solar-panel.svg';
import { ReactComponent as IconLeaves } from 'assets/icons/icon-leaves.svg';
import { ReactComponent as IconNature } from 'assets/icons/icon-nature.svg';
import { ReactComponent as IconElectricCar } from 'assets/icons/icon-electric-car.svg';
import { ReactComponent as IconChimney } from 'assets/icons/icon-chimney.svg';
import { ReactComponent as IconBackAppBar } from 'assets/icons/icon-back-app-bar.svg';
import { ReactComponent as IconHelp } from 'assets/icons/icon-help.svg';
import { ReactComponent as IconFacebook } from 'assets/icons/icon-facebook.svg';
import { ReactComponent as IconApple } from 'assets/icons/icon-apple.svg';
import { ReactComponent as IconGoogle } from 'assets/icons/icon-google.svg';
import { ReactComponent as IconClose } from 'assets/icons/icon-close.svg';
import { ReactComponent as IconDownArrowForButton } from 'assets/icons/icon-arrow-down-for-button.svg';
import { ReactComponent as IconSun } from 'assets/icons/icon-sun.svg';
import { ReactComponent as IconStar } from 'assets/icons/icon-star.svg';
import { ReactComponent as IconTime } from 'assets/icons/icon-time.svg';
import { ReactComponent as IconPowerSupply } from 'assets/icons/icon-power-supply.svg';
import { ReactComponent as IconGraph } from 'assets/icons/icon-graph.svg';
import { ReactComponent as IconMoreInformation } from 'assets/icons/icon-more-information.svg';
import { ReactComponent as IconCharging } from 'assets/icons/icon-charging.svg';
import { ReactComponent as IconLightning } from 'assets/icons/icon-lightning.svg';
import { ReactComponent as IconTag } from 'assets/icons/icon-tag.svg';
import { ReactComponent as IconSignature } from 'assets/icons/icon-signature.svg';
import { ReactComponent as IconCalendar } from 'assets/icons/icon-calendar.svg';
import { ReactComponent as IconCoins } from 'assets/icons/icon-coins.svg';
import { ReactComponent as IconCheckBgGreen } from 'assets/icons/icon-check-bg-green.svg';
import { ReactComponent as IconCheckBgYellow } from 'assets/icons/icon-check-bg-yellow.svg';
import { ReactComponent as IconXBgRed } from 'assets/icons/icon-x-bg-red.svg';
import { ReactComponent as IconProfile } from 'assets/icons/icon-profile.svg';
import { ReactComponent as IconTagColorful } from 'assets/icons/icon-tag-colorful.svg';
import { ReactComponent as IconLaborer } from 'assets/icons/icon-laborer.svg';
import { ReactComponent as IconIdea } from 'assets/icons/icon-idea.svg';
import { ReactComponent as IconTransfer } from 'assets/icons/icon-transfer.svg';
import { ReactComponent as IconManage } from 'assets/icons/icon-manage.svg';
import { ReactComponent as IconTrash } from 'assets/icons/icon-trash.svg';
import IconCopyPaste from 'assets/icons/icon-copy-paste.png';
import IconCopyPasteColorful from 'assets/icons/icon-copy-paste-colorful.png';
import { ReactComponent as IconPlus } from 'assets/icons/icon-plus.svg';
import { ReactComponent as IconCloseButton } from 'assets/icons/icon-close-button.svg';
import { ReactComponent as IconOpenButton } from 'assets/icons/icon-open-button.svg';
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as IconFullscreen } from 'assets/icons/icon-fullscreen.svg';
import { ReactComponent as IconListArrow } from 'assets/icons/icon-list-arrow.svg';
import { ReactComponent as IconMenu } from 'assets/icons/icon-menu.svg';
import { ReactComponent as IconFile } from 'assets/icons/icon-file.svg';
import { ReactComponent as IconImage } from 'assets/icons/icon-image.svg';
import { ReactComponent as IconReplace } from 'assets/icons/icon-replace.svg';
import { ReactComponent as IconCard } from 'assets/icons/icon-card.svg';
import { ReactComponent as IconPrinter } from 'assets/icons/icon-printer.svg';
import { ReactComponent as IconFinance } from 'assets/icons/icon-finance.svg';
import { ReactComponent as IconMinus } from 'assets/icons/icon-minus.svg';
import { ReactComponent as IconRotate } from 'assets/icons/icon-rotate.svg';
import { ReactComponent as IconPdfDownload } from 'assets/icons/icon-pdf-download.svg';
import { ReactComponent as IconHouseElectricity } from 'assets/icons/icon-house-electricity.svg';
import { ReactComponent as IconContactUs } from 'assets/icons/icon-contact.svg';
import { ReactComponent as IconZoomOut } from 'assets/icons/icon-zoom-out.svg';
import { ReactComponent as IconCloseFilled } from 'assets/icons/icon-close-filled.svg';
import { ReactComponent as IconLocation } from 'assets/icons/location-pin.svg';
import { ReactComponent as IconCamera } from 'assets/icons/icon-camera.svg';

const Icon = {
    Account: IconAccount,
    Back: IconBack,
    Book: IconBook,
    Check: IconCheck,
    DownArrow: (props: { fill?: string; width?: string; height?: string; rotate?: number }) => (
        <IconDownArrow {...props} style={{ transform: `rotate(${props?.rotate}deg)` }} />
    ),
    Info: IconInfo,
    SolarPanel: (props: { fill?: string; width?: string; height?: string }) => <IconSolarPanel {...props} />,
    Leaves: IconLeaves,
    Nature: IconNature,
    ElectricCar: IconElectricCar,
    Chimney: IconChimney,
    BackAppBar: (props: { fill?: string }) => <IconBackAppBar {...props} />,
    Help: IconHelp,
    Facebook: IconFacebook,
    Apple: IconApple,
    Google: IconGoogle,
    Close: (props: { width?: string; height?: string }) => <IconClose {...props} />,
    DownArrowForButton: IconDownArrowForButton,
    Sun: (props: { fill?: string }) => <IconSun {...props} />,
    Star: IconStar,
    Time: (props: { width?: string; height?: string; fill?: string }) => <IconTime {...props} />,
    PowerSupply: IconPowerSupply,
    Graph: (props: { width?: string; height?: string; fill?: string }) => <IconGraph {...props} />,
    MoreInformation: IconMoreInformation,
    Charging: IconCharging,
    Lighting: IconLightning,
    Tag: IconTag,
    Signature: IconSignature,
    Calendar: (props: { width?: string; height?: string; fill?: string }) => <IconCalendar {...props} />,
    Coins: IconCoins,
    CheckBgGreen: IconCheckBgGreen,
    CheckBgYellow: IconCheckBgYellow,
    XBgRed: IconXBgRed,
    AccountColorful: IconAccountColorful,
    Profile: IconProfile,
    TagColorful: IconTagColorful,
    Laborer: IconLaborer,
    Idea: IconIdea,
    Transfer: IconTransfer,
    Manage: IconManage,
    CopyPaste: () => <img src={IconCopyPaste} width='18px' height='18px' alt='IconCopyPaste' />,
    CopyPasteColorful: () => <img src={IconCopyPasteColorful} width='18px' height='18px' alt='IconCopyPasteColorful' />,
    Trash: (props: { width?: string; height?: string }) => <IconTrash {...props} />,
    Plus: (props: { width?: string; height?: string; fill?: string; stroke?: string }) => <IconPlus {...props} />,
    CloseButton: IconCloseButton,
    OpenButton: IconOpenButton,
    Save: (props: { width?: string; height?: string; fill?: string }) => <SaveIcon {...props} />,
    Edit: (props: { width?: string; height?: string; fill?: string }) => <EditIcon {...props} />,
    Fullscreen: IconFullscreen,
    ZoomOut: IconZoomOut,
    ListArrow: (props: { fill?: string; style?: Object }) => <IconListArrow {...props} />,
    Menu: IconMenu,
    File: (props: { fill?: string }) => <IconFile {...props} />,
    Image: IconImage,
    Replace: IconReplace,
    Card: IconCard,
    Printer: IconPrinter,
    Finance: IconFinance,
    Minus: IconMinus,
    Rotate: IconRotate,
    PdfDownload: IconPdfDownload,
    HouseElectricity: IconHouseElectricity,
    ContactUs: (props: { width?: string; height?: string }) => <IconContactUs {...props} />,
    CloseFilled: IconCloseFilled,
    Location: IconLocation,
    Camera: IconCamera,
};

export default Icon;
