import { useTheme } from '@mui/material';
import { Box, Button, ButtonSize, Typography } from 'components/atoms';
import DownloadFullQuotation from 'components/organisms/DownloadFullQuotation';
import { ContractTypeModel } from 'models/common';
import { FC } from 'react';
import texts from 'texts.json';

interface IProps {
    isCompany: boolean;
    disabled: boolean;
    isFullQuotation: boolean;
    selectedTab: ContractTypeModel;
    onClick: () => void;
    onFullQuotationDownload: () => void;
}

const ResultsButtons: FC<IProps> = ({ isCompany, onClick, disabled, isFullQuotation, onFullQuotationDownload, selectedTab }) => {
    const theme = useTheme();

    const {
        buttons: { get_quotations, download_quotation_purchase, download_quotation_rent },
        can_see_results,
    } = texts.results;
    const companyButtonTitle = selectedTab === ContractTypeModel.PURCHASE ? download_quotation_purchase : download_quotation_rent;
    const buttonTitle = isCompany ? companyButtonTitle : get_quotations;

    return (
        <Box
            display={'flex'}
            width={'100%'}
            flexDirection={{ md: 'row', xs: 'column' }}
            alignItems={{ md: 'baseline', xs: 'center' }}
            justifyContent={'center'}
            columnGap={'10px'}
        >
            <Box width={{ md: '52%', xs: '100%' }} textAlign={'center'}>
                <Button.Contained font={theme.fontFamilies.bold} size={ButtonSize.LARGE} onClick={onClick} disabled={disabled}>
                    {buttonTitle}
                </Button.Contained>
                {!isCompany && <Typography.Body4>{can_see_results}</Typography.Body4>}
            </Box>
            {isFullQuotation && <DownloadFullQuotation onClick={onFullQuotationDownload} disabled={disabled} modelType={selectedTab} />}
        </Box>
    );
};

export default ResultsButtons;
